import { Http, HttpModule } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { NotifierService } from '../notifier.service';
import { Observable } from 'rxjs';


@Injectable()
export class UsersService {
 token: String;
 serverUrl = environment.serverUrl;
 appUrl = environment.appUrl;
 dToken;
    constructor(
        private http: Http,
        private router: Router,
        private http1: HttpClient
){}
// get header data
    getHeader() {

        const aToken = localStorage.getItem('access_token');
        const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${aToken}`
            })
        };
        return httpOptions;
    }
    getCount() {
        return this.http1.get( this.serverUrl + 'users/count', this.getHeader()).map(response => response);

    }

    // get logged in report
    getReport() {
        return this.http1.get( this.serverUrl + 'users/logged-in-report', this.getHeader()).map(response => response);

    }
    // upload image
    upLoadImage(fileToUpload: File):Observable<any>  {
        const formData: FormData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);

        return this.http.post(this.serverUrl+ 'users/uploadImage', formData)
            .map(response => response.json());
    }
    // get list of users
    getUsers() {
        return this.http1.get( this.serverUrl + 'users', this.getHeader()).map(response => response);
    }

    findUsers(data) {
        return this.http1.post( this.serverUrl + 'users/search', data, this.getHeader()).map(response => response);
    }
    // change user level
    changeMemberLevel(data){
        return this.http.put(this.serverUrl + 'users/member-level', data).map(response => response.json().data);
    }


    signInasUser( data ) {
        this.http.post(this.serverUrl + 'users/signin', data).subscribe( res => {
            localStorage.setItem('id_token', res.json().data);
            const url = `${this.appUrl}login/${res.json().data}`;
            window.open(url, '_blank');
        });
    }

    lockdown(data) {
        return this.http.put(this.serverUrl + 'users/lockdown', data).map(response => response.json().data);
    }

    // get backup history list
    backuplist() {
        return this.http1.get(this.serverUrl + 'backup/history', this.getHeader()).map(response => response);
    }
}
