import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SignupComponent } from './authentiction/signup/signup.component';
import { SigninComponent } from './authentiction/signin/signin.component';
import { HomeComponent } from './core/home/home.component';
import { UsersComponent } from './users/users.component';
import { SendMfaCodeComponent } from './authentiction/send-mfa-code/send-mfa-code.component';
import { VerifyCodeComponent } from './authentiction/verify-code/verify-code.component';
import { ForgetpassComponent } from './authentiction/forgetpass/forgetpass.component';
import { ResetpasswordComponent } from './authentiction/resetpassword/resetpassword.component';
import { ColorCrudComponent } from './color-crud/color-crud.component';
import { LockdownComponent } from './lockdown/lockdown.component';
import { TestReportsComponent } from './test-reports/test-reports.component';
import { CreateTeamComponent } from './create-team/create-team.component';

const appRoutes: Routes = [
{ path: '', component: SigninComponent },
{ path: 'signin', component: SigninComponent },
// { path: 'signup', component: SignupComponent },
{ path: 'M/:slug', component: HomeComponent },
{ path: 'members', component: UsersComponent },
{ path: 'lockdown', component: LockdownComponent },
{ path: 'test-reports', component: TestReportsComponent },
{ path: 'color-themes', component: ColorCrudComponent },
{ path: 'send-mfa-code', component: SendMfaCodeComponent },
{ path: 'verify-mfa-code', component: VerifyCodeComponent },
{ path: 'reset-password', component: ForgetpassComponent },
{ path: 'resetpassword/:token', component: ResetpasswordComponent },
{ path: 'create-Team', component: CreateTeamComponent },
];

@NgModule({
imports:[RouterModule.forRoot(appRoutes)],
exports: [RouterModule],
})
export class AppRouting {

}